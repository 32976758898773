import React, { FC } from 'react';

import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import PaymentSteps from 'pages/start/payments/components/PaymentSteps';
import List from '../List';
import Disclaimer from 'components/Disclaimer';
import BannerOne from '../BannerOne';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import ImageTextCheckBoxSection from '../ImageTextCheckBoxSection';
import ImageGrid from '../ImageGrid';
import NumberedList from '../NumberedList';
import ReviewsCardsList from 'components/ReviewCardsList';
import UpgradeDisclaimer from '../UpgradeDisclaimer';

interface FastingMadeEasyProps {}

const Container = styled.div``;

const ListContainer = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
`;

const ListStyledBot = styled(List)`
  max-width: 42.5rem;
`;

const FastingMadeEasy: FC<FastingMadeEasyProps> = ({
  paymentSteps,
  userHasBoughtPaperbackBook,
  planSection,
  planSection2,
  PlanSectionComponent,
  imageTextCheckBoxSection,
  numberedList,
  reviewsSection,
  imageGrid,
  list2,
  disclaimer,
  bannerOne,
}) => {
  const PlanSectionComponentStyled = styled(PlanSectionComponent)`
    padding: 4rem 1rem;
    @media ${tablet} {
      padding: 2.25rem 1rem;
    }
  `;
  const selectedPlans = useSelector(
    (state: AppState) => state.user.selected_plans,
  );

  const hasEbookOrPaperback = selectedPlans.some(
    product =>
      product.key.includes('e-book') || product.key.includes('paper-back-book'),
  );
  return (
    <>
      {hasEbookOrPaperback && bannerOne && (
        <BannerOne
          {...bannerOne}
          boughtPaperback={userHasBoughtPaperbackBook}
        />
      )}

      <PaymentSteps
        {...paymentSteps}
        hasOptionalStep={userHasBoughtPaperbackBook}
      />

      <ImageTextCheckBoxSection {...imageTextCheckBoxSection} />
      <PlanSectionComponentStyled {...planSection} />
      <ImageGrid {...imageGrid} />
      <NumberedList {...numberedList} />
      <PlanSectionComponentStyled {...planSection2} />
      <ReviewsCardsList {...reviewsSection} />
      <UpgradeDisclaimer {...disclaimer} />
    </>
  );
};

export default FastingMadeEasy;
